<template>
  <div class="">
    <div class="pay-header">
      <header>
        <div class="pay-content">
          <div class="header-content-container-center">
            <div class="lkpMGK">
              <div class="bMZJfX">
                <!-- <h1 class="KoZMg">
                  <div class="eiCGSs">WePlay</div>
                </h1> -->
                <h2 style="margin-left: 10px">
                  <img
                    style="width: 150px"
                    src="@/assets/logo.png"
                    alt=""
                    srcset=""
                  />
                </h2>
              </div>
              <!-- <div class="iZUGJW">
                <a
                  href="https://id.funtap.vn/login?response_type=code&amp;reditectTo=nap.funtap.vn"
                  color="orange"
                  class="jOSmlA qNoRP"
                  >Đăng nhập</a
                >
              </div> -->
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="pay-content">
      <div class="pay-center">
        <div class="pay-main">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(img, index) in bannerList"
                :key="index"
              >
                <img :src="img.img" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="pay-center-choose">
            <div class="pay-center-channel-list">
              <a
                class="pay-center-channel-item"
                v-for="(item, index) in channelList"
                :key="index"
              >
                <div class="pay-center-channel-img">
                  <img :src="item.imgUrl" alt="" srcset="" />
                </div>
                {{ item.name }}
              </a>
            </div>
          </div>
          <div class="pay-center-line"></div>
          <div class="pay-my-game">
            <div class="pay-game-title">
              <h3 class="pay-game-title-text">Game của bạn</h3>
            </div>
            <div class="pay-my-game-list">
              <div class="swiper-game-container">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(item, index) in gameList"
                    :key="index"
                    @click="handleToGame(item)"
                  >
                    <div class="swiper-game-content">
                      <img class="swiper-game-img" :src="item.img_url" />
                      <div class="swiper-game-name">{{ item.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="swiper-game-pagination"></div>
              </div>
            </div>
          </div>
          <div class="pay-center-line"></div>
          <div class="game-list">
            <div class="pay-game-title">
              <h3 class="pay-game-title-text">Danh sách game</h3>
              <el-input
                placeholder="Tìm game"
                suffix-icon="el-icon-search"
                v-model="input"
                style="width: 200px; top: 5px"
                @input="handleGameInput"
              >
              </el-input>
            </div>
            <div class="pay-game-list" v-if="chooseGame.length > 0">
              <div
                class="pay-game-item"
                v-for="(item, index) in chooseGame"
                :key="index"
                @click="handleToGame(item)"
              >
                <!-- <router-link :to="String(item.id)"> -->
                <div class="pay-game-img-content">
                  <img
                    class="pay-game-img"
                    :src="item.img_url"
                    alt=""
                    srcset=""
                  />
                  <img
                    class="pay-game-icon"
                    src="https://nap.funtap.vn/images/ico-hot.svg"
                    alt=""
                    srcset=""
                  />
                </div>
                <h4>{{ item.name }}</h4>
                <!-- </router-link> -->
              </div>
            </div>
            <div class="pay-game-empty" v-else>
              <div class="pay-game-empty-text">Không tìm thấy game nào</div>
              <el-button
                type="warning"
                class="pay-game-empty-btn"
                @click="handleEmptyBtn()"
                >Bỏ lọc</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Swiper from "swiper";
import Footer from "../../components/footer";
export default {
  name: "",
  mixins: [],
  components: { Footer },
  props: {},
  data() {
    return {
      input: "",
      bannerList: [],
      channelList: [],
      gameList: [],
      chooseGame: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.chooseGame = this.gameList;
    // this.$demoDataBase.setItem("1001", "我是测试的");
    // this.$demoDataBase.getItem("1001").then(function (value) {
    //   // Do other things once the value has been saved.
    //   console.log(value);
    // });
    this.getGameData();
  },
  mounted() {},
  methods: {
    //获取游戏
    getGameData() {
      this.$http.post("/apis/api/website/gameList").then((res) => {
        if (res.code === 200) {
          this.gameList = res.data;
          this.chooseGame = res.data;
          this.$nextTick(function () {
            new Swiper(".swiper-game-container", {
              effect: "coverflow",
              slidesPerView: "auto",
              coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
                slideShadows: true,
              },
              pagination: {
                el: ".swiper-game-pagination",
                clickable: true,
              },
            });
          });
        }
      });
      this.$http.post("/apis/api/website/banner").then((res) => {
        if (res.code === 200) {
          this.bannerList = res.data;
          this.$nextTick(function () {
            new Swiper(".swiper-container", {
              autoplay: true,
              loop: true,
              mousewheel: true,
              keyboard: true,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
            });
          });
        }
      });
    },
    handleGameInput(e) {
      let game = [];
      this.gameList.forEach((item) => {
        if (item.name.includes(e)) {
          game.push(item);
        }
      });
      this.chooseGame = game;
    },
    //清空搜索栏
    handleEmptyBtn() {
      this.input = "";
      this.chooseGame = this.gameList;
    },
    handleToGame(item) {
      this.$router.push({
        path: "/gameInfo",
        query: { g: item.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./css/pc.scss";
@import "./css/phone.scss";
</style>
